import React from "react";
import { createRoot } from "react-dom/client";
import $ from "jquery/src/jquery";
import "stylesheets/login/App.css";
import { Login } from "./Login";

window.$ = $;
window.jQuery = $;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Login />);
