import React, { Component } from "react";
import axios from "axios";
import Logo from "images/logo-jumpseller-blackbg.svg";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailPlaceholder: window.translations.emailPlaceholder,
      passwordPlaceholder: window.translations.passwordPlaceholder,
      errorMessage: "",
      showError: false,
      site_key: window.state.site_key,
      site_key_v2: window.state.site_key_v2,
      grecaptcha: window.state.grecaptcha,
      use_recaptcha_v2: false,
    };

    this.inputHandler = this.inputHandler.bind(this);
    this.sendData = this.sendData.bind(this);
    this.sendReceivedData = this.sendReceivedData.bind(this);
    this.toggleError = this.toggleError.bind(this);
  }

  inputHandler(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  sendReceivedData(email, password, token) {
    axios
      .post(window.translations.login_url, {
        email: email,
        password: password,
        "g-recaptcha-response": token,
        using_recaptcha_v2: this.state.use_recaptcha_v2,
      })
      .then((response) => {
        if (!response.data.error) {
          window.location = response.data.url;
        } else {
          this.setState({
            errorMessage: response.data.message,
            showError: true,
          });
          if (response.data.bot) {
            this.setState({ use_recaptcha_v2: true });
            this.state.grecaptcha.ready(() => {
              this.state.grecaptcha.render("recaptcha-container", {
                sitekey: this.state.site_key_v2,
              });
            });
          }
          this.state.grecaptcha.reset();
        }
      });
  }

  sendData(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const token = formData.get("g-recaptcha-response");

    if (!event.target.checkValidity()) {
      this.toggleError();
    } else {
      this.sendReceivedData(this.state.email, this.state.password, token);
    }
  }

  toggleError() {
    this.setState({
      showError: !this.state.showError,
    });
  }

  render() {
    return (
      <form
        acceptCharset="UTF-8"
        className="form-signin"
        onSubmit={this.sendData}
      >
        <img className="mb-4" src={Logo} alt="jumpseller-logo" width="200" />
        <h1 className="h3 mb-3 font-weight-normal">
          {window.translations.title}
        </h1>
        <hr />

        {this.state.errorMessage && this.state.showError && (
          <div className="alert alert-danger fade show">
            {this.state.errorMessage}
            <button type="button" className="close" onClick={this.toggleError}>
              <i className="far fa-times" />
            </button>
          </div>
        )}

        <input
          type="email"
          className="form-control"
          onChange={this.inputHandler}
          id="email"
          name="email"
          autoComplete="email"
          placeholder={this.state.emailPlaceholder}
          required
          autoFocus
        />

        <input
          type="password"
          onChange={this.inputHandler}
          className="form-control"
          id="password"
          name="password"
          autoComplete="current-password"
          minLength="6"
          placeholder={this.state.passwordPlaceholder}
          required
        />
        <div className="form-group">
          <div
            id="recaptcha-container"
            className="d-flex justify-content-center"
          ></div>
        </div>
        {this.state.use_recaptcha_v2 && (
          <button
            id="login-translate-submit"
            type="submit"
            className="btn btn-lg btn-primary btn-block mt-3"
          >
            {window.translations.loginButton}
          </button>
        )}
        {!this.state.use_recaptcha_v2 && (
          <div>
            <button
              id="login-translate-submit"
              type="submit"
              style={{ display: "none" }}
            >
              Login
            </button>
            <button
              className="btn btn-lg btn-primary btn-block mt-3 g-recaptcha"
              data-sitekey={this.state.site_key}
              data-callback="onSubmitLogin"
              data-action="submit"
            >
              {window.translations.loginButton}
            </button>
          </div>
        )}
      </form>
    );
  }
}
